import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import menuData from "./restaurantmenu.json";
import { useState, useEffect } from 'react';
import Rating from './Rating.component';
import LiquorIcon from "@mui/icons-material/Liquor";

function RestaurantAccordionComponent() {
    const [menuDataSt, setMenuDataSt] = useState(window.restaurantMenu);
    // const menuRef = useRef();
   
    // const getData=()=>{
    //     fetch('data/restaurantmenu.json'
    //     ,{
    //       headers : { 
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //        }
    //     }
    //     )
    //       .then(function(response){
    //         console.log(response)
            
    //         return response.json();
    //       })
    //       .then(function(myJson) {
    //         setMenuDataSt(myJson)
    //         console.log(myJson);
    //       });
    //   }
    // useEffect(()=>{
    //     getData();
    // },[])
    return (
        <div className='accordionMain'>
            {/* <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Accordion Item #1</Accordion.Header>
                    <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                    <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion> */}

            <Accordion defaultActiveKey="0">
                {menuDataSt&& menuDataSt?.length>0 && menuDataSt?.map((item, index) => {
                    return (<Accordion.Item eventKey={index}>
                        <Accordion.Header>{item.menuSection}</Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                {/* <Col className="menuItemsSection m-2">
                                    <div className="meniItemsHeading  d-flex flex-row justify-content-between">
                                        <span>Takeaway Menu</span>
                                    </div>
                                    {item?.takeAwayMenu?.map((dish, index1) => {
                                        return (
                                            <div className="menuItemsArea">
                                                <div className="row">
                                                    <div class="col">
                                                        <div className="menuItemHeading">{dish?.itemName} </div>
                                                        {dish?.descriptionDk != "" && <div className="menuItemDescription">DK: {dish?.descriptionDk}</div>}
                                                        {dish?.decriptionEn != "" && <div className="menuItemDescription">EN: {dish?.decriptionEn}</div>}
                                                    </div>
                                                    <div class="col col-md-3">
                                                        {dish?.qty != "" && <div className="menuItemQty">{dish?.qty}</div>}
                                                        {dish?.price != "" && <div className="menuItemPrice">{dish?.price}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </Col> */}
                                <Col className="menuItemsSection m-2">
                                {item?.restaurantMenu?.map((dish, index1) => {
                                return (
                                    <div className="menuItemsArea">
                                        <div className="row">
                                            <div className="col">
                                                <div className="menuItemHeading d-flex flex-row justify-content-between align-items-start">
                                                    <div className="textDiv">{dish?.itemName} {dish?.spicyRating && dish?.spicyRating > 0 && <span className="spiceRating">
                                                        {/* <>{dish?.spicyRating}</> */}
                                                        <Rating spicyRating={dish.spicyRating} />
                                                    </span>

                                                    }
                                                    {dish?.qty && dish?.qty != "" && <span style={{ marginLeft: "10px" }}> ({dish?.qty})</span>}

                                                    {dish?.pickle && dish?.pickle != "" && <span style={{ marginLeft: "10px" }}> {dish?.pickle}</span>}
                                                    </div>
                                                    
                                                    <div className="amountDiv">
                                                    {dish?.price != "" && <div className="dishPrice">{dish?.price}</div>}</div>
                                                    
                                                </div>
                                                {dish?.descriptionDk != "" && <div className="menuItemDescription">DK: {dish?.descriptionDk}</div>}
                                                {dish?.decriptionEn != "" && <div className="menuItemDescription">EN: {dish?.decriptionEn}</div>}
                                            </div>

                                            {/* <div className="col col-md-3">
                                                {dish?.price != "" && <div className="menuItemPrice">{dish?.price}</div>}
                                            </div> */}
                                        </div>
                                    </div>
                                )
                            })}
                            {/* {item?.combo?.gin?.length>0 && <h1 className="text-center">Gin</h1>} */}
                            <div className="row ginMenuArea">
                                {/* {item?.subSection?.length>0 && <div><h4 className="drinks-menu-title">{item?.menuSection}</h4>
                                    
                                    </div>} */}
                                {item?.subSection && item?.subSection?.map((dish, index1) => {
                                    return (
                                        <div className="row">
                                            {dish?.drinkData?.length > 0 && <div><h4 className="drinks-menu-title">{dish?.drinkName}</h4>
                                            </div>}
                                            <div className="row" style={{borderBottom:"1px solid #ccc", marginBottom:"20px"}}>
                                            {dish?.drinkData?.length > 0 && dish?.drinkData?.map(drink => {
                                                return (
                                                    <div className="menuItemsArea col-md-4">
                                                        {/* <div className="row">
                                                    <div className="col">
                                                        <div className="d-flex flex-column">
                                                            <div className="ginMenuHeading">{dish?.itemName}</div>
        
        
                                                            <ul className=" ginMenuItems">
                                                                {dish?.items && dish?.items?.map(tr => {
                                                                    return (<li className="">{tr}</li>)
                                                                }
                                                                )}
        
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                        <div className="card">
                                                            {/* <img className="card-img-top" src="..." alt="Card image cap" /> */}
                                                            {/* <div className="card-img-top d-flex flex-row justify-content-center"><LiquorIcon fontSize="large" style={{ fontSize: "130px" }} /></div> */}
                                                            <div className="card-body">
                                                                <h5 className="card-title d-flex flex-row justify-content-between"><span className="drinkTitle">{drink?.itemName}</span><span>{drink?.price}</span></h5>
                                                                {drink?.decriptionEn && <p className="card-text">{drink?.decriptionEn}</p>}
                                                            </div>
                                                            <ul className="list-group list-group-flush">
                                                                {drink?.items && drink?.items?.map(tr => {
                                                                    return (<li className="list-group-item">{tr}</li>)
                                                                }
                                                                )}

                                                            </ul>
                                                            {/* <div className="card-body">
                                                                <a href="#" className="card-link">Card link</a>
                                                                <a href="#" className="card-link">Another link</a>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            </div>
                                        </div>

                                    )
                                })}
                            </div>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>)
                })}
            </Accordion>

        </div>

    );
}

export default RestaurantAccordionComponent;