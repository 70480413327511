
// import menuData from "./takeawaymenu.json";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState,useEffect } from "react";
import Rating from "./Rating.component";
import LiquorIcon from "@mui/icons-material/Liquor";
export default function TakeawayComponent() {
    const [key, setKey] = useState('FORRETTER - STARTERS');
    const [menuDataSt, setMenuDataSt] = useState(window.takeawayMenu);
    // const getData=()=>{
    //     fetch('data/takeawaymenu.json'
    //     ,{
    //       headers : { 
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //        }
    //     }
    //     )
    //       .then(function(response){
    //         console.log(response)
            
    //         return response.json();
    //       })
    //       .then(function(myJson) {
    //         setMenuDataSt(myJson)
    //         console.log(myJson);
    //       });
    //   }
    // useEffect(()=>{
    //     getData();
    // },[])
    return (<Tabs
        id="menuTabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 d-flex flex-column"
    >
        {menuDataSt && menuDataSt?.length>0 && menuDataSt?.map((item, index) => {
            return (
                <Tab eventKey={item.menuSection} title={item.menuSection}>
                    <Row>
                        <Col className="menuItemsSection m-2">
                            {/* <div className="meniItemsHeading  d-flex flex-row justify-content-between">
                                <span className="text-bold text-capitalize">{item.menuSection}</span>
                                <span>Takeaway Menu</span>
                            </div> */}
                            {item?.takeAwayMenu && item?.takeAwayMenu?.map((dish, index1) => {
                                return (
                                    // <div className="menuItemsArea">
                                    //     <div className="row">
                                    //         <div class="col">
                                    //             <div className="menuItemHeading">{dish?.itemName} </div>
                                    //             {dish?.descriptionDk != "" && <div className="menuItemDescription">DK: {dish?.descriptionDk}</div>}
                                    //             {dish?.decriptionEn != "" && <div className="menuItemDescription">EN: {dish?.decriptionEn}</div>}
                                    //         </div>
                                    //         <div class="col col-md-3">
                                    //             {dish?.qty != "" && <div className="menuItemQty">{dish?.qty}</div>}
                                    //             {dish?.price != "" && <div className="menuItemPrice">{dish?.price}</div>}
                                    //         </div>
                                    //     </div>
                                    // </div>
                                    <div className="menuItemsArea">
                                        <div className="row">
                                            <div class="col">
                                                <div className="menuItemHeading">
                                                    {dish?.itemName} {dish?.spicyRating && dish?.spicyRating > 0 && <span className="spiceRating">
                                                        {/* <>{dish?.spicyRating}</> */}
                                                        <Rating spicyRating={dish.spicyRating} />
                                                    </span>

                                                    }
                                                    {dish?.qty && dish?.qty != "" && <span style={{ marginLeft: "10px" }}> ({dish?.qty})</span>}

                                                    {dish?.pickle && dish?.pickle != "" && <span style={{ marginLeft: "10px" }}> {dish?.pickle}</span>}

                                                    <span style={{ fontWeight: "normal", margin: "0px 10px", flexGrow: 1 }}></span>
                                                    {dish?.price != "" && <div className="dishPrice">{dish?.price}</div>}
                                                </div>
                                                {dish?.descriptionDk != "" && <div className="menuItemDescription">DK: {dish?.descriptionDk}</div>}
                                                {dish?.decriptionEn != "" && <div className="menuItemDescription">EN: {dish?.decriptionEn}</div>}
                                            </div>

                                            {/* <div class="col col-md-3">
                                            {dish?.price != "" && <div className="menuItemPrice">{dish?.price}</div>}
                                        </div> */}
                                        </div>
                                    </div>
                                )
                            })}


                            {item?.subSection && item?.subSection?.map((dish, index1) => {
                                return (
                                    <div className="row">
                                        {dish?.drinkData?.length > 0 && <div><h4 className="drinks-menu-title">{dish?.drinkName}</h4>
                                        </div>}
                                        <div className="row" style={{ marginBottom: "20px"}}>
                                            {dish?.drinkData?.length > 0 && dish?.drinkData?.map(drink => {
                                                return (
                                                    <div className="menuItemsArea col-md-4">
                                                        {/* <div className="row">
                                                    <div className="col">
                                                        <div className="d-flex flex-column">
                                                            <div className="ginMenuHeading">{dish?.itemName}</div>
        
        
                                                            <ul className=" ginMenuItems">
                                                                {dish?.items && dish?.items?.map(tr => {
                                                                    return (<li className="">{tr}</li>)
                                                                }
                                                                )}
        
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                        <div className="card">
                                                            {/* <img className="card-img-top" src="..." alt="Card image cap" /> */}
                                                            {/* <div className="card-img-top d-flex flex-row justify-content-center"><LiquorIcon fontSize="large" style={{ fontSize: "130px" }} /></div> */}
                                                            <div className="card-body">
                                                                <h5 className="card-title d-flex flex-row justify-content-between"><span className="drinkTitle">{drink?.itemName}</span><span>{drink?.price}</span></h5>
                                                                {drink?.decriptionEn && <p className="card-text">{drink?.decriptionEn}</p>}
                                                            </div>
                                                            <ul className="list-group list-group-flush">
                                                                {drink?.items && drink?.items?.map(tr => {
                                                                    return (<li className="list-group-item">{tr}</li>)
                                                                }
                                                                )}

                                                            </ul>
                                                            {/* <div className="card-body">
                                                                <a href="#" className="card-link">Card link</a>
                                                                <a href="#" className="card-link">Another link</a>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>

                                )
                            })}
                            <div className="comboMainParent">
                                {item?.combo?.normalCombo && item?.combo?.normalCombo?.map((dish, index1) => {
                                    return (

                                        <div className="menuItemsArea comboSections">
                                            <div className="row"  style={{width:"100%"}}>
                                                <div class="col"  style={{width:"100%"}}>
                                                    <div className="card d-flex flex-column"   style={{height:"100%"}}>
                                                        <div className="comboHeading drinkHeading" style={{ textAlign: "left", padding: "10px 30px" }}>{dish?.itemName}</div>

                                                        <ul className="list-style-none combo-menu-item-section">
                                                            {dish?.items && dish?.items?.map(tr => {
                                                                return (<li className="">{tr}</li>)
                                                            }
                                                            )}

                                                        </ul>
                                                        <ul className="list-style-none combo-menu-item-section">
                                                            <li className="col-md-6">Normal Price: {dish?.normalPrice}</li>
                                                            <li className="col-md-6">Tilbud pris: {dish?.comboPrice}</li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </Col>
                        
                    </Row>
                </Tab>
            )
        })}
        
    </Tabs>)
}