import React from 'react';

const Rating = ({ spicyRating }) => {
  // Function to generate an array of chili icons based on spicy rating
 // Create an array with 'n' elements to map over
 const spiceArr = Array.from({ length: spicyRating }, (v, i) => i);

 return (
   <div  className='d-flex flex-row align-items-center'>
     {spiceArr.map((index) => (
       <div key={index}>
         {/* Content inside each div */}
         <img src={"./images/chili.png"} style={{width:"25px", height:"25px"}}/>
       </div>
     ))}
   </div>
 );
};
export default Rating;