import { ParallaxBanner } from "react-scroll-parallax";
import "./reservationArea.component.css";
import { Button } from "react-bootstrap";
import { Parallax } from 'react-parallax';
export default function ReservationAreaComponent(){
    const background = {
        image:
          "./images/kris-atomic-39750.jpg",
        translateY: [0, 20],
        speed:100,
        // opacity: [1, 0.3],
        // scale: [1.05, 1, "easeOutCubic"],
        shouldAlwaysCompleteAnimation: true
      };
    
      const headline = {
        translateY: [-20, 10],
        speed:100,
        // scale: [1, 1.05, "easeOutCubic"],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,
        children: (
          <div className="inset center d-flex flex-column reservationParallax">
            
            {/* <div className="reservationHeader">Reservation</div>
            <div className="divA small-white"></div>
            <div className="divB small-white"></div> */}
            <div className="reservationSubHeader">Book a table</div>
            <div className="reservationDesciption">
            Enjoy amazing authentic Indian food at our cozy restaurant. You can book a table by our booking system or call 40886969. We promise that you can enjoy hot and fresh food in the restaurant without missing smell and taste
            </div>
            
            <Button variant="primary" size="xl" className="margin-top-2 "  onClick={event =>  window.location.href='https://indiaspice.dk/reservation.php'}>Book a Table</Button>
          </div>
        )
      };
    
      const foreground= {
        image:
          "https://s3-us-west-2.amazonaws.com/s.cdpn.io/105988/banner-foreground.png",
        translateY: [0, 15],
        scale: [1, 1.1, "easeOutCubic"],
        shouldAlwaysCompleteAnimation: true
        
      };
    
      const gradientOverlay = {
        opacity: [0, 1, "easeOutCubic"],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,
        children: <div className="gradient inset" />
      };
    return(
    //     <ParallaxBanner
    //     speed={50}
    //   layers={[background, headline, foreground]}
    //   className="full"
    //   style={{
    //     height: '70vh',
    // }}
    // />
    <Parallax bgImage={"./images/kris-atomic-39750.jpg"} strength={500} style={{height:"80vh"}}>
      <div className="inset center d-flex flex-column reservationParallax">
            
            {/* <div className="reservationHeader">Reservation</div>
            <div className="divA small-white"></div>
            <div className="divB small-white"></div> */}
            <div className="reservationSubHeader">Book a table</div>
            <div className="divA"></div>
            <div className="divB"></div>
            <div className="reservationDesciption">
            Enjoy amazing authentic Indian food at our cozy restaurant. You can book a table by our booking system or call 40886969. We promise that you can enjoy hot and fresh food in the restaurant without missing smell and taste
            </div>
            
            <Button variant="primary" size="xl" className="margin-top-2 " ><a  href="https://india-spice.resos.com/booking" target="_blank" style={{color:"#fff", textDecoration:"none"}}>Book a Table</a></Button>
          </div>
    </Parallax>
    )
}