import { Button } from "react-bootstrap";
import { ParallaxBanner } from "react-scroll-parallax";
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useState, useRef, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReservationAreaComponent from "./reservationArea.component";
import { useMatchMedia } from "../../hooks/useMatchMedia";
import RestaurantIcon from '@mui/icons-material/Restaurant';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RestaurantComponent from "./restaurant.component";
import TakeawayComponent from "./takeaway.component";
import HeaderComponent from "../common/header.component";
import SearchIcon from '@mui/icons-material/Search';
import RestaurantAccordionComponent from "./RestaurantAccordion.component";
import TakeawayAccordionComponent from "./takeawayAccordion.component";
import { Phone } from "@mui/icons-material";
import CarouselModal from "./CarouselModal.component";
import ImageSlider from "./ImageSlider.component";


export default function HomeComponent() {
	const [key, setKey] = useState('FORRETTER - STARTERS');
	const isDesktopResolution = useMatchMedia("(min-width:992px)", true);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [width, setWidth] = useState();
	const [height, setHeight] = useState();

	// useRef allows us to "store" the div in a constant, 
	// and to access it via observedDiv.current
	const observedDiv = useRef(null);

	useEffect(() => {
		if (!observedDiv.current) {
			// we do not initialize the observer unless the ref has
			// been assigned
			return;
		}

		// we also instantiate the resizeObserver and we pass
		// the event handler to the constructor
		const resizeObserver = new ResizeObserver(() => {
			if (observedDiv.current.offsetWidth !== width) {
				setWidth(observedDiv.current.offsetWidth);
			}
			if (observedDiv.current.offsetHeight !== height) {
				setHeight(observedDiv.current.offsetHeight);
			}
		});

		// the code in useEffect will be executed when the component
		// has mounted, so we are certain observedDiv.current will contain
		// the div we want to observe
		resizeObserver.observe(observedDiv.current);


		// if useEffect returns a function, it is called right before the
		// component unmounts, so it is the right place to stop observing
		// the div
		return function cleanup() {
			resizeObserver.disconnect();
		}
	},
		// only update the effect if the ref element changed
		[observedDiv.current])
	const images = [
		'./images/364790.jpg',
		'./images/364825.jpg',
		'./images/364888.jpg',
		'./images/389360.jpg',
		// Add more images as needed
	];
	const openModal = (index) => {
		setSelectedImage(index);
		setModalOpen(true);
	};

	const closeModal = () => {
		setSelectedImage(null);
		setModalOpen(false);
	};
	// const background = {
	//     image: "/images/night.jpeg",
	//     translateY: [0, 50],
	//     opacity: [1, 0.3],
	//     scale: [1.05, 1, "easeOutCubic"],
	//     shouldAlwaysCompleteAnimation: true,
	// };
	// const headline = {
	//     translateY: [0, 30],
	//     scale: [1, 1.05, "easeOutCubic"],
	//     shouldAlwaysCompleteAnimation: true,
	//     expanded: false,
	//     children: (
	//         <div className="absolute inset-0 flex items-center justify-center">
	//             <h1 className="text-6xl md:text-8xl text-white font-thin">WELCOME!</h1>
	//         </div>
	//     ),
	// };
	// const foreground = {
	//     image: "./kris-atomic-39750.jpg",
	//     translateY: [0, 15],
	//     scale: [1, 1.1, "easeOutCubic"],
	//     shouldAlwaysCompleteAnimation: true,
	// };
	// const gradientOverlay = {
	//     opacity: [0, 0.9],
	//     shouldAlwaysCompleteAnimation: true,
	//     expanded: false,
	//     children: (
	//         <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-blue-900" />
	//     ),
	// };
	return (
		<div className="container-body">
			<HeaderComponent ></HeaderComponent>
			{/* <ParallaxBanner
                layers={[background, headline, foreground, gradientOverlay]}
                className="bg-container"
            /> */}
			<div className="topBanner-container  d-flex justify-content-between" id="topBannerId">
				{/* <video className="videoBackground" src={"./India Spice Curries.mp4"} poster="./videoLoader.png" preload="none" autoPlay loop muted></video> */}
				{/* <div className="background-overlay"></div> */}
				<div className="yt-video-holder" ref={observedDiv}>
					<iframe width={width} height={height} src={`${window.YOUTUBE_URL}&autoplay=1&mute=1&loop=1&controls=0&color=white&modestbranding=0&rel=0&playsinline=1&enablejsapi=1&playlist=otvbzKnI6Tw&listType=playlist`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
				</div>
				<div className="left-contact d-flex flex-row justify-content-end"><Phone />+45 40886969</div>
				<div className="mid-content  d-flex flex-column justify-content-center align-items-center">
					<h2>Welcome to</h2>
					<h2>INDIA SPICE</h2>
					<div className="divA"></div>
					<div className="divB"></div>
					<p>Enjoy the best authentic delicious Indian food in our cozy restaurant at Søborg. You can feel a complete word of taste with spicy, sour, sweet flavours, and hot all at the same time.</p>
					<div className="button-container">
						<Button variant="primary" size="xl" onClick={event => window.location.href = 'https://india-spice.resos.com/booking'}>Reserve Table</Button>
						<Button variant="primary" size="xl" onClick={event => window.location.href = 'https://indiaspice.nemtakeaway.dk/order.php'}>Order Online</Button>
					</div>
					<div className="homeSpecialAnnouncement">
						<h4>BEST AUTHENTIC INDIAN FOOD</h4>
						<h4>FREE DELIVERY</h4>
					</div>

				</div>
				<div className="right-links  d-flex flex-column m-2">
					<button type="button" class="btn btn-outline-primary"><a style={{ color: "#fff" }} href="https://www.instagram.com/indiaspice.dk/"><InstagramIcon /></a></button>
					<button type="button" class="btn btn-outline-primary"><a style={{ color: "#fff" }} href="https://www.facebook.com/indiaSpice01"><FacebookIcon /></a></button>
					<button type="button" class="btn btn-outline-primary"><a style={{ color: "#fff" }} href="https://x.com/IndiaspiceD"><XIcon /></a></button>
				</div>
				<div className="bannerWave">
					<svg class="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
						<path class="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
					</svg>
				</div>
			</div>
			<div className="menuArea">
				<div className="menuSection" id="restaurantMenuId">
					<div className="menuItemSection">
						<h2 className="sectionHeading">Restaurant Menu</h2><button className="btn btn-secondary orderButton" ><a style={{ textDecoration: "none", color: "#000" }} href="https://india-spice.resos.com/booking" target="_blank">Book a Table</a></button>
						{/* <div className="restaurantMenuArea">
							<div className="mainMenuArea"><RestaurantIcon className="menuIconHome"></RestaurantIcon><span>Restaurant Menu</span></div>
							<div className="mainMenuArea orderBookingBtn"><TableRestaurantIcon className="menuIconHome"></TableRestaurantIcon><a style={{textDecoration:"none", color:"#ed3237"}}href="https://india-spice.resos.com/booking" target="_blank">Book a Table</a></div>
						</div> */}


					</div>
					<div className="menuContentSection">
						{isDesktopResolution && <RestaurantComponent />}
						{!isDesktopResolution && <RestaurantAccordionComponent />}
					</div>


				</div>
				<div className="menuSection" id="takeAwayMenuId">

					<div className="menuItemSection">
						<h2 className="sectionHeading">Takeaway Menu</h2><button className="btn btn-secondary orderButton"><a style={{ textDecoration: "none", color: "#000" }} href="https://indiaspice.nemtakeaway.dk/order.php#delivery_type_modal" target="_blank">Order online</a></button>
						{/* <div className="takeawayMenuArea">
							<div className="mainMenuArea"><TakeoutDiningIcon className="menuIconHome"></TakeoutDiningIcon><span>Takeaway Menu</span></div>
							<div className="mainMenuArea orderBookingBtn"><AddShoppingCartIcon className="menuIconHome"></AddShoppingCartIcon><a  style={{textDecoration:"none", color:"#ed3237"}} href="https://indiaspice.nemtakeaway.dk/order.php#delivery_type_modal" target="_blank">Order online</a></div>
						</div> */}


					</div>
					<div className="menuContentSection">
						{isDesktopResolution && <TakeawayComponent />}
						{!isDesktopResolution && <TakeawayAccordionComponent />}
					</div>


				</div>
			</div>
			{/* <div className="discoverArea d-flex flex-row justify-content-between" id="">
				<div className="leftImage"></div>
				<div className="rightContent">
					<div className="discoverHeading">Discover</div>
					<div className="divA small-red"></div>
					<div className="divB  small-red"></div>
					<div className="discoverSubHeading">Authentic Indian Food</div>
					<div className="discoverdescription">
						Enjoy the best authentic delicious Indian food in our cozy restaurant at Søborg. You can feel a complete word of taste with spicy, sour, sweet flavours, and hot all at the same time. At our India Spice restaurant, we prepare and serve/deliver almost 70+ varieties of tasty Indian dishes full of unique spices. We guarantee that our guests will have a great experience of the best authentic Indian food. We prepare vegetarian and non-vegetarian (chicken, lamb and fish) main courses with starters and Hyderabadi Dum Biryani varieties. We serve hot and delicious Indian food inside the restaurant at Søborg and deliver to 15 km radius. We also do catering for parties within 30 km radius.
					</div>
					<Button variant="primary" size="xl" className="margin-top-2 " onClick={event => window.location.href = '/about'}>About Us</Button>
				</div>
			</div> */}
			<div className="reservationArea" id="bookATableId">
				<ReservationAreaComponent />
			</div>
			<div className="cateringArea d-flex flex-row justify-content-center" id="cateringMenuId">

				<div className="leftContent">
					<div className="reservationSubHeader">Catering</div>
					<div className="divA"></div>
					<div className="divB"></div>
					{/* <div className="cateringSubHeading">Special Events</div> */}
					<div className="cateringDescription">
						Do you need authentic Indian food for your corporate event, company or party? Then you are always welcome to contact us regardless of size. We offer catering for both large and small events.
					</div>
					{/* <Button variant="primary" size="xl" className="margin-top-2 " onClick={event => window.location.href = '/catering'}>Go to Events</Button> */}
				</div>
				<div className="rightImage"></div>
			</div>

			{/* <div className="foodGrid">
				<div className="food-container">
					<a className="pic-grid Col-1" href="https://www.instagram.com/indiaspice.dk/" target="_blank">
						<div className="instagramOverlay">
							<SearchIcon />
						</div>

					</a>
					<a className="pic-grid Col-2" href="https://www.instagram.com/indiaspice.dk/" target="_blank">
						<div className="instagramOverlay">
							<SearchIcon /></div></a>
					<a className="pic-grid Col-3" href="https://www.instagram.com/indiaspice.dk/" target="_blank">
						<div className="instagramOverlay">
							<SearchIcon /></div></a>
					<a className="pic-grid Col-4" href="https://www.instagram.com/indiaspice.dk/" target="_blank">
						<div className="instagramOverlay">
							<SearchIcon /></div></a>
					<a className="pic-grid col-2-3" href="https://www.instagram.com/indiaspice.dk/" target="_blank">
						<div className="instagramOverlay">
							<SearchIcon /></div></a>
				</div>
			</div> */}
			
			{/* <section id="about">
				<div class="about-wrapper container">
					<div class="about-text">
						<div className="leftContent">
							<div className="cateringHeading">Chief’s Word</div>
							<div className="divA small-red"></div>
							<div className="divB  small-red"></div>
							<div className="cateringSubHeading">MADE WITH LOVE</div>
							<div className="cateringDescription">
								Our cooks are making Indian Delicious food from last 2 decades. They know the traditional Indian spices, and masalas.
							</div>
							
						</div>
					</div>
					<div class="about-img">
						<img src="chief-photo-p-800.jpeg" alt="food" />
					</div>
				</div>
			</section> */}
			{/* <div className="imageCorauselArea" style={{margin:"0px 0px 80px 0px"}}>
			<ImageSlider />
			</div> */}

			<div className="locationArea d-flex flex-column align-items-center" id="aboutandFindUs">
				<svg class="location-area-wave footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
					<path class="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
				</svg>
				<div className="reservationSubHeader find-us-header">Find Us</div>
				<div className="divA small-red"></div>
				<div className="divB  small-red"></div>
				{/* <div className="locationSubHeading">Easy to Find</div> */}
				<div className="aboutUsContainerFooter">
					<div className="row locationGrid">
						<div className="openingHoursArea col-md">
							{/* <div className="companyLogo d-flex flex-column align-items-center justify-content-center">
								<img src="./Logo.png" width="150" />
								<h3 style={{ fontSize: "1.5em", fontWeight: "bold", marginBottom: "20px" }}>Opening Hours</h3>
								<p style={{ marginBottom: "20px" }}>Mon - Sun: 16:00 - 21:30</p>
							</div> */}
							<div className="card">
								<div className="companyLogo d-flex flex-column align-items-center justify-content-center">
									<img src="./images/Logo.png" width="150" />
									<h3 style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "20px" }}>Opening Hours</h3>
									{/* <p style={{ marginBottom: "20px" }}>Mon - Sun: 16:00 - 21:30</p> */}
									<div class="opening-hours-table">
										<div class="opening-hours-cell">
											<div>Monday</div>
											
											<div class="opening-hours-time">16:00 - 21:30</div>
										</div>
										<div class="opening-hours-cell">
											<div>Tuesday</div>
											
											<div class="opening-hours-time">16:00 - 21:30</div>
										</div>
										<div class="opening-hours-cell">
											<div>Wednesday</div>
											
											<div class="opening-hours-time">16:00 - 21:30</div>
										</div>
										<div class="opening-hours-cell">
											<div>Thursday</div>
											
											<div class="opening-hours-time">16:00 - 21:30</div>
										</div>
										<div class="opening-hours-cell">
											<div>Friday</div>
											
											<div class="opening-hours-time">16:00 - 21:30</div>
										</div>
										<div class="opening-hours-cell">
											<div>Saturday</div>
											
											<div class="opening-hours-time">16:00 - 21:30</div>
										</div>
										<div class="opening-hours-cell">
											<div>Sunday</div>
											
											<div class="opening-hours-time">16:00 - 21:30</div>
										</div>
									</div>
								</div>


							</div>
						</div>
						<div className="addressArea  col-md  d-flex flex-column align-items-center justify-content-center">
							<div className="card col-md  d-flex flex-column align-items-center justify-content-center">
								<img src="./images/location-icon_1location-icon.png" width="70px" height="80px" style={{ marginBottom: "20px" }} />
								<p className="text-center m-2">Søborg Hovedgade 118 B, 2860 Søborg, Denmark</p>
								<p className="text-center m-2">info@indiaspice.dk</p>
								<p className="text-center m2">+45 40886969</p>
							</div>


						</div>
						<div className="mapArea  col-md">
							<div className="google-map-code">
								<div className="card" style={{ height: "100%" }}>
									<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4492.4669786524455!2d12.509669!3d55.737072!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465251f91cd1f3b9%3A0x9cb9a9db7e9143c3!2sS%C3%B8borg%20Hovedgade%20118%2C%202860%20S%C3%B8borg%2C%20Denmark!5e0!3m2!1sen!2sin!4v1717358933424!5m2!1sen!2sin" style={{ border: "0", height: "100%", width: "100%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
								</div>

							</div>
						</div>
						<div className="aboutUsArea  col-lg">
							<div className="card">
								<div className="chiefsWord">
									<div className="locationHeading smallAboutUs">About Us</div>
									<div className="divA small-red" style={{ borderBottom: "2px solid #000" }}></div>
									<div className="divB  small-red" style={{ borderBottom: "1px solid #000" }}></div>
									<div className="aboutUsContentFooter">
										Our cooks are making Indian Delicious food from last 2 decades. They know the traditional Indian spices, and masalas.
									</div>
								</div>
							</div>

						</div>

					</div>
				</div>

			</div>

		</div>)
}