
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomeComponent from './pages/home/home.component';
import Contactcomponent from './pages/contact/contact.component';
import Cateringcomponent from './pages/catering/catering.component';
import Aboutcomponent from './pages/about/about.component';
import NotFoundComponent from './pages/notfound/notfound.component';
import HeaderComponent from './pages/common/header.component';
import FooterComponent from './pages/common/footer.component';
import TakeawayMenuComponent from './pages/menu/takeawayMenu.component';
import RestaurantMenuComponent from './pages/menu/restaurantMenu.component';
export function MainComponent() {
    return (
        <div className="wrapper">
            
            <Router>
                <Routes>
                    <Route exact path="/" element={<HomeComponent />} />
                    {/* <Route exact path="/takeawaymenu" element={<TakeawayMenuComponent />} />
                    <Route exact path="/restaurantmenu" element={< RestaurantMenuComponent/>} />
                    <Route exact path="/contact" element={<Contactcomponent />} />
                    <Route exact path="/catering" element={<Cateringcomponent />} />
                    <Route exact path="/about" element={<Aboutcomponent />} /> */}
                    <Route path="*" element={<NotFoundComponent />} />
                </Routes>
                <FooterComponent />
            </Router>
        </div>
    )
}