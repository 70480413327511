
// import menuData from "./restaurantmenu.json";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useRef, useState } from "react";
import Rating from "./Rating.component";
import LiquorIcon from '@mui/icons-material/Liquor';
export default function RestaurantComponent() {
    const [key, setKey] = useState('FORRETTER - STARTERS');
    const [menuDataSt, setMenuDataSt] = useState(window.restaurantMenu);
    // const menuRef = useRef();
    function handleClick() {
        const menuRef = document.getElementById("menuTabs");
        // menuRef.scrollIntoView({ behavior: "smooth" });
    }
    // const getData=()=>{
    //     fetch('data/restaurantmenu.json'
    //     ,{
    //       headers : { 
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //        }
    //     }
    //     )
    //       .then(function(response){
    //         console.log(response)
            
    //         return response.json();
    //       })
    //       .then(function(myJson) {
    //         setMenuDataSt(myJson)
    //         console.log(myJson);
    //       });
    //   }
    useEffect(()=>{
        // getData();
    },[])
    return (<Tabs
        id="menuTabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 d-flex flex-column"
        onClick={handleClick}
    >
        {menuDataSt && menuDataSt?.length>0 && menuDataSt?.map((item, index) => {
            return (
                <Tab eventKey={item.menuSection} title={item.menuSection}>
                    <Row>
                        {/* <Col className="menuItemsSection m-2">
                            <div className="meniItemsHeading  d-flex flex-row justify-content-between">
                                <span className="text-bold text-capitalize">{item.menuSection}</span>
                                <span>Takeaway Menu</span>
                            </div>
                            {item?.takeAwayMenu?.map((dish, index1) => {
                                return (
                                    <div className="menuItemsArea">
                                        <div className="row">
                                            <div className="col">
                                                <div className="menuItemHeading">{dish?.itemName} </div>
                                                {dish?.descriptionDk != "" && <div className="menuItemDescription">DK: {dish?.descriptionDk}</div>}
                                                {dish?.decriptionEn != "" && <div className="menuItemDescription">EN: {dish?.decriptionEn}</div>}
                                            </div>
                                            <div className="col col-md-3">
                                                {dish?.qty != "" && <div className="menuItemQty">{dish?.qty}</div>}
                                                {dish?.price != "" && <div className="menuItemPrice">{dish?.price}</div>}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </Col> */}
                        <Col className="menuItemsSection m-2">
                            {/* <div className="meniItemsHeading d-flex flex-row justify-content-between">
                                <span className="text-bold text-capitalize">{item.menuSection}</span>
                                <span>In House Menu</span>
                            </div> */}
                            {item?.restaurantMenu?.map((dish, index1) => {
                                return (
                                    <div className="menuItemsArea">
                                        <div className="row">
                                            <div className="col">
                                                <div className="menuItemHeading">
                                                    {dish?.itemName} {dish?.spicyRating && dish?.spicyRating > 0 && <span className="spiceRating">
                                                        {/* <>{dish?.spicyRating}</> */}
                                                        <Rating spicyRating={dish.spicyRating} />
                                                    </span>

                                                    }
                                                    {dish?.qty && dish?.qty != "" && <span style={{ marginLeft: "10px" }}> ({dish?.qty})</span>}

                                                    {dish?.pickle && dish?.pickle != "" && <span style={{ marginLeft: "10px" }}> {dish?.pickle}</span>}

                                                    <span style={{ fontWeight: "normal", margin: "0px 10px", flexGrow: 1 }}></span>
                                                    {dish?.price != "" && <div className="dishPrice">{dish?.price}</div>}
                                                </div>
                                                {dish?.descriptionDk != "" && <div className="menuItemDescription">DK: {dish?.descriptionDk}</div>}
                                                {dish?.decriptionEn != "" && <div className="menuItemDescription">EN: {dish?.decriptionEn}</div>}
                                            </div>

                                            {/* <div className="col col-md-3">
                                                {dish?.price != "" && <div className="menuItemPrice">{dish?.price}</div>}
                                            </div> */}
                                        </div>
                                    </div>
                                )
                            })}
                            {/* {item?.combo?.gin?.length>0 && <h1 className="text-center">Gin</h1>} */}
                            <div className="row ginMenuArea">
                                {/* {item?.subSection?.length>0 && <div><h4 className="drinks-menu-title">{item?.menuSection}</h4>
                                    
                                    </div>} */}
                                {item?.subSection && item?.subSection?.map((dish, index1) => {
                                    return (
                                        <div className="row">
                                            {dish?.drinkData?.length > 0 && <div><h4 className="drinks-menu-title">{dish?.drinkName}</h4>
                                            </div>}
                                            <div className="row" style={{borderBottom:"1px solid #ccc", marginBottom:"20px"}}>
                                            {dish?.drinkData?.length > 0 && dish?.drinkData?.map(drink => {
                                                return (
                                                    <div className="menuItemsArea col-md-4">
                                                        {/* <div className="row">
                                                    <div className="col">
                                                        <div className="d-flex flex-column">
                                                            <div className="ginMenuHeading">{dish?.itemName}</div>
        
        
                                                            <ul className=" ginMenuItems">
                                                                {dish?.items && dish?.items?.map(tr => {
                                                                    return (<li className="">{tr}</li>)
                                                                }
                                                                )}
        
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                        <div className="card">
                                                            {/* <img className="card-img-top" src="..." alt="Card image cap" /> */}
                                                            {/* <div className="card-img-top d-flex flex-row justify-content-center"><LiquorIcon fontSize="large" style={{ fontSize: "130px" }} /></div> */}
                                                            <div className="card-body">
                                                                <h5 className="card-title d-flex flex-row justify-content-between"><span className="drinkTitle">{drink?.itemName}</span><span>{drink?.price}</span></h5>
                                                                {drink?.decriptionEn && <p className="card-text">{drink?.decriptionEn}</p>}
                                                            </div>
                                                            <ul className="list-group list-group-flush">
                                                                {drink?.items && drink?.items?.map(tr => {
                                                                    return (<li className="list-group-item">{tr}</li>)
                                                                }
                                                                )}

                                                            </ul>
                                                            {/* <div className="card-body">
                                                                <a href="#" className="card-link">Card link</a>
                                                                <a href="#" className="card-link">Another link</a>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            </div>
                                        </div>

                                    )
                                })}
                            </div>

                            {/* {item?.combo?.vodka?.length>0 && <h1 className="text-center">Vodka</h1>}
                            {item?.combo?.vodka && item?.combo?.vodka?.map((dish, index1) => {
                                return (
                                    <div className="menuItemsArea">
                                        <div className="row">
                                            <div className="col">
                                                <div className="d-flex flex-column">
                                                    <div className="comboHeading center">{dish?.itemName}</div>


                                                    <ul className="list-style-none combo-menu-item-section">
                                                        {dish?.items && dish?.items?.map(tr => {
                                                            return (<li className="text-center">{tr}</li>)
                                                        }
                                                        )}

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })} */}

                            {/* {item?.combo?.rum?.length>0 && <h1 className="text-center">Rum</h1>}
                            {item?.combo?.rum && item?.combo?.rum?.map((dish, index1) => {
                                return (
                                    <div className="menuItemsArea">
                                        <div className="row">
                                            <div className="col">
                                                <div className="d-flex flex-column">
                                                    <div className="comboHeading center">{dish?.itemName}</div>


                                                    <ul className="list-style-none combo-menu-item-section">
                                                        {dish?.items && dish?.items?.map(tr => {
                                                            return (<li className="text-center">{tr}</li>)
                                                        }
                                                        )}

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })} */}

                            {/* {item?.combo?.whiskey?.length>0 && <h1 className="text-center">Whiskey</h1>}
                            {item?.combo?.whiskey && item?.combo?.whiskey?.map((dish, index1) => {
                                return (
                                    <div className="menuItemsArea">
                                        <div className="row">
                                            <div className="col">
                                                <div className="d-flex flex-column">
                                                    <div className="comboHeading center">{dish?.itemName}</div>


                                                    <ul className="list-style-none combo-menu-item-section">
                                                        {dish?.items && dish?.items?.map(tr => {
                                                            return (<li className="text-center">{tr}</li>)
                                                        }
                                                        )}

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })} */}
                        </Col>
                    </Row>
                </Tab>
            )
        })}
        {/* <Tab eventKey="FORRETTER - STARTERS" title="FORRETTER - STARTERS">
            <Row>
                <Col className="menuItemsSection m-2">
                    <div className="meniItemsHeading  d-flex flex-row justify-content-between">
                        <span className="text-bold text-capitalize">FORRETTER - STARTERS</span>
                        <span>Takeaway Menu</span>
                    </div>
                </Col>
                <Col className="menuItemsSection m-2">
                    <div className="meniItemsHeading d-flex flex-row justify-content-between">
                        <span className="text-bold text-capitalize">FORRETTER - STARTERS</span>
                        <span>In House Menu</span>
                    </div>
                </Col>
            </Row>
        </Tab>
        <Tab eventKey="BØRNEMENU - CHILDREN MENU" title="BØRNEMENU - CHILDREN MENU" >
            Tab content for Contact
        </Tab>
        <Tab eventKey="SALATER - SALADS" title="SALATER - SALADS" >
            Tab content for Contact
        </Tab>
        <Tab eventKey="KYLLING-CHICKEN" title="KYLLING-CHICKEN" >
            Tab content for Contact
        </Tab>
        <Tab eventKey="LAM--LAMB" title="LAM--LAMB" >
            Tab content for Contact
        </Tab>
        <Tab eventKey="VEGETAR-VEGETARIAN" title="VEGETAR-VEGETARIAN" >
            Tab content for Contact
        </Tab>
        <Tab eventKey="REJER - PRAWNS" title="REJER - PRAWNS" >
            Tab content for Contact
        </Tab>
        <Tab eventKey="INDIA SPICE SPECIAL BIRYANIES" title="INDIA SPICE SPECIAL BIRYANIES" >
            Tab content for Contact
        </Tab>
        <Tab eventKey="TILBEHØR" title="TILBEHØR" >
            Tab content for Contact
        </Tab>
        <Tab eventKey="NAAN BRØD-- NAAN BREAD" title="NAAN BRØD-- NAAN BREAD" >
            Tab content for Contact
        </Tab>
        <Tab eventKey="DESSERTER" title="DESSERTER" >
            Tab content for Contact
        </Tab>
        <Tab eventKey="TE –TEA" title="TE –TEA" >
            Tab content for Contact
        </Tab>
        <Tab eventKey="KOLDE DRIKKE-- COLD DRINKS" title="KOLDE DRIKKE-- COLD DRINKS" >
            Tab content for Contact
        </Tab>
        <Tab eventKey="ØL - BEER" title="ØL - BEER" >
            Tab content for Contact
        </Tab>
        <Tab eventKey="VIN – WINE" title="VIN – WINE" >
            Tab content for Contact
        </Tab> */}

    </Tabs>)
}