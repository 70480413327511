import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import "./css/fonts.css";

import './css/slick.css';
import './css/style.css';
import './App.css';
import './css/responsive.css';
import { MainComponent } from './component/main.component';
import { ParallaxProvider } from 'react-scroll-parallax';
import SwiperCore, { Autoplay } from 'swiper';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// css
import 'swiper/swiper.min.css'
// import 'swiper/modules/pagination/pagination.min.css'

function App() {
  AOS.init({disable: 'mobile'});
  SwiperCore.use([Autoplay])
  return (
    <ParallaxProvider>
      <MainComponent />
      </ParallaxProvider>
    
  );
}

export default App;
