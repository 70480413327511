import { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, useLocation } from 'react-router-dom';
import { Link } from 'react-scroll';
export default function HeaderComponent() {
  const [showNavbar1, setShowNavbar1] = useState(false);
  const [showNavbar2, setShowNavbar2] = useState(false);
  const [sticky, setSticky] = useState(false);
  const location = useLocation();
  const [headerClass, setHeaderClass] = useState();
  useEffect(() => {
    document.addEventListener("scroll", trackScroll);
    if (location.pathname == "/") {

      setHeaderClass("home")
    } else {
      setHeaderClass("")
    }

    handleSetActive("topBannerId");
    return () => {
      document.removeEventListener("scroll", trackScroll);
    };
  }, [])
  const trackScroll = () => {
    if (typeof window === "undefined") {
      return;
    } else {
      setSticky(window.scrollY >= 10);
    }
  };
  const handleShowNavbar1 = () => {
    setShowNavbar1(!showNavbar1);
  };
  const handleShowNavbar2 = () => {
    setShowNavbar2(!showNavbar2);
  };
  const stickyStyle = {
    transform: `translateY(${sticky ? "0px" : "-100%"})`
  };

  const handleSetActive = (to) => {
    console.log(to);
  };
  return (
    <>
      <nav className={"navbar navbar-fixed " + headerClass} >
        <div className="container mainMeunContainer">
          <div className="logo">
            <Link to="topBannerId"  spy={true} smooth={true} offset={0} duration={500}  style={{cursor:"pointer"}}><img src="./images/Logo.png" /></Link>
          </div>
          <div className="menu-icon" onClick={handleShowNavbar1}>
            <MenuIcon />
          </div>
          <div className={`nav-elements  ${showNavbar1 && "active"}`}>
            <ul>
            <li>
              <Link to="topBannerId" onSetActive={handleSetActive} spy={true} smooth={true} offset={-100} duration={500}>Home</Link>
              </li>
              <li>
                <Link to="restaurantMenuId" spy={true} smooth={true} offset={-90} duration={500}>Restaurant Menu</Link>
              </li>
              <li>
                <Link to="takeAwayMenuId" spy={true} smooth={true} offset={-90} duration={500}>Takeaway Menu</Link>
              </li>
              <li>
                <Link to="bookATableId" spy={true} smooth={true} offset={-100} duration={500}>Book a Table</Link>
              </li>
              <li>
                <Link to="cateringMenuId" spy={true} smooth={true} offset={-100} duration={500}>Catering</Link>
              </li>
              <li>
                <Link to="aboutandFindUs" spy={true} smooth={true} offset={-100} duration={500}>Find Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <nav className={"navbar navbar-main nav-sticky"} style={stickyStyle} >
        <div className="container  mainMeunContainer sticky">
          <div className="logo">
            <div className="logo">
              <Link to="topBannerId"  spy={true} smooth={true} offset={-100} duration={500} style={{cursor:"pointer"}}><img src="./images/Logo.png" /></Link>
            </div>
          </div>
          <div className="menu-icon" onClick={handleShowNavbar2}>
            <MenuIcon />
          </div>
          <div className={`nav-elements  ${showNavbar2 && "active"}`}>
            <ul>
            <li>
              <Link to="topBannerId" onSetActive={handleSetActive} spy={true} smooth={true} offset={-100} duration={500}>Home</Link>
              </li>
              <li>
                <Link to="restaurantMenuId" spy={true} smooth={true} offset={-90} duration={500}>Restaurant Menu</Link>
              </li>
              <li>
                <Link to="takeAwayMenuId" spy={true} smooth={true} offset={-90} duration={500}>Takeaway Menu</Link>
              </li>
              <li>
                <Link to="bookATableId" spy={true} smooth={true} offset={-100} duration={500}>Book a Table</Link>
              </li>
              <li>
                <Link to="cateringMenuId" spy={true} smooth={true} offset={-100} duration={500}>Catering</Link>
              </li>
              <li>
                <Link to="aboutandFindUs" spy={true} smooth={true} offset={-100} duration={500}>Find Us</Link>
              </li>
              {/* <li>
                <NavLink to="/about">About and Find Us</NavLink>
              </li> */}
              {/* <li>
                <NavLink to="/contact">Contact</NavLink>
              </li> */}
              {/* <li>
                <button type="button" class="btn btn-outline-primary tableBookingBtn"><a style={{ textDecoration: "none", color: "#ed3237" }} href="https://india-spice.resos.com/booking" target="_blank">Book a Table</a></button>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>


  )
}